@media only screen and (max-width: 950px) {
  .inputnonmobile {
    display: none !important;
  }
  .candidatmaindiv {
    width: 100% !important;
  }
  .candidatedis {
    display: none !important;
  }
  .candidatedismobile {
    display: block !important;
  }
  .MuiBox-root css-19kzrtu {
    padding: 0 !important;
  }
  .mobilepadding {
    padding: 0 5px !important;
  }
  .inputtextmobilediv {
    display: block !important;
    margin: 0 25px !important;
  }
  .widthfull {
    width: 100% !important;
  }
  .inputtextmobile {
    display: block !important;
    width: 100% !important;
    display: flex !important;
    margin-bottom: 10px !important;
    margin-top: 0 !important;
  }

  /* poppupcandidate */

  .popcanmaindiv {
    width: 100% !important;
  }
  .popupcandidatediv {
    display: block !important;
  }
  .popsavebutton {
    width: 100% !important;
  }

  /* Savecandidate */

  .Savecandidate {
    width: 100% !important;
  }
}
