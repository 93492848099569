@media only screen and (max-width: 950px) {
.Client_Canditate{
display: block !important;
}
.Client_Canditate_child{
    display: block !important;
}
.scrollmain {
    overflow: scroll !important;
    width: 100% !important;
    display: block !important;
    margin-bottom: 20px !important;
    border-bottom: 1px solid #f9eaea !important;
  }
  .scrollreqursion {
    overflow-x: scroll !important;
    overflow-y: scroll !important;
    width: 1150px !important;
  }
  .Client_Canditate_search{
    display: block !important;
    text-align: end !important;

    margin-bottom: 10px;
  }
  .paddremov{
    padding: 0 !important;
    margin-bottom: 10px !important;
  }
  .serch_input{
    width: 170px !important;
  }
  .position{
    position: absolute !important;
  }
  .popstw{
    display: grid !important;
    padding-right: 50px !important;
  }
  .pops{
    display: grid !important;
  }
}