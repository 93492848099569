.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.logo {
  float: left;
  margin: 0;
  padding: 20px;
}
/* header style */
.header {
  background-color: orange;
  height: 100px;
}
.cart-div {
  float: right;
  margin: 30px;
  position: relative;
}
.cart-div img {
  width: 30px;
}
.cart-div span {
  background-color: green;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  position: absolute;
  top: -10px;
  right: 0px;
  color: #fff;
}

/* end header style */

/* product UI style */
.product-container {
  display: flex;
  flex-wrap: wrap;
}
.product-item {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 20px;
}
/* end product Ui style */

/* cart page style */
.cart-page-container {
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
}
.cart-page-container table {
  flex: 65%;
  border-collapse: collapse;
}
.cart-page-container table td {
  border: 1px solid #ccc;
  padding: 15px;
}
.price-details {
  flex: 35%;
  margin: 0px 30px;
}
.price-details .adjust-price {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
.price-details .adjust-price span {
  font-size: 20px;
}
/* end cart page style */
.search-box {
  float: left;
  width: 60%;
  padding: 25px;
}
.search-box input {
  width: 100%;
  height: 30px;
  padding-left: 10px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.mainscrollnav {
  width: 100% !important;
  overflow: visible !important;
  position: relative !important;
}

.scroll-arrow {
  display: none !important; 
  position: static !important;
  /* right: 0px;
  top: 127px; */
  transform: translateY(-50%) !important;
  font-size: 24px !important;
  cursor: pointer !important;
  z-index: 10 !important;
  padding: 0 10px !important;
  background: rgba(255, 255, 255, 0.8) !important;
  animation: blink 1s infinite !important;
}

@media only screen and (max-width: 950px) {
  .scroll-arrow {
    display: block !important;
    position: absolute !important; 
    right: 0px !important;
    top: 127px !important;
    cursor: pointer !important;

  }
  .navLogo {
    width: 56px !important;
    margin-left: 20px !important;
  }
  .peranav {
    font-size: 12px !important;
  }
  .logoutNav {
    margin: 0 !important;
    padding: 0 !important;
    width: 55px !important;
  }
  .fonttab {
    font-size: 13px !important;
  }
  .maincontainerhead {
    padding: 0 3px !important;
  }
  .mainscrollnav {
    width: 100% !important;
    overflow: scroll !important;
  }
  .scrolldivtwo {
    overflow-y: scroll !important;
    overflow-x: scroll !important;
    width: 1500px !important;
    padding: 0 0 4px 0 !important;
  }
  .scrolldivtwo::-webkit-scrollbar {
    display: none !important;
  }
  .mainscrollnav::-webkit-scrollbar {
    display: none !important;
  }
}

@media only screen and (max-width: 1300px) {
  .scroll-arrow {
    display: block !important;
    position: absolute !important;
    right: 0px !important;
    top: 127px !important;
    cursor: pointer !important;
  }
  .navLogo {
    width: 56px !important;
    margin-left: 20px !important;
  }
  .peranav {
    font-size: 12px !important;
  }
  .logoutNav {
    margin: 0 !important;
    padding: 0 !important;
    width: 55px !important;
  }
  .fonttab {
    font-size: 13px !important;
  }
  .maincontainerhead {
    padding: 0 3px !important;
  }
  .mainscrollnav {
    width: 100% !important;
    overflow: scroll !important;
  }
  .scrolldivtwo {
    overflow-y: scroll !important;
    overflow-x: scroll !important;
    width: 1500px !important;
    padding: 0 0 4px 0 !important;
  }
  .scrolldivtwo::-webkit-scrollbar {
    display: none !important;
  }
  .mainscrollnav::-webkit-scrollbar {
    display: none !important;
  }
}

/*   userAndClientindex   */

/* @media only screen and (max-width: 950px) {
  .navLogo {
    width: 56px !important;
    margin-left: 20px !important;
  }
  .peranav {
    font-size: 12px !important;
  }
  .logoutNav {
    margin: 0 !important;
    padding: 0 !important;
    width: 55px !important;
  }
  .fonttab {
    font-size: 13px !important;
  }
  .maincontainerhead {
    padding: 0 3px !important;
  }

  .mainscrollnav {
    width: 100% !important;
    overflow: scroll !important;
  }
  .scrolldivtwo {
    overflow-y: scroll !important;
    overflow-x: scroll !important;
    width: 1500px !important;
    padding: 0 0 4px 0 !important;
  }
  .scrolldivtwo::-webkit-scrollbar {
    display: none;
  }
  .mainscrollnav::-webkit-scrollbar {
    display: none;
  }
}


@media only screen and (max-width: 1300px) {
  .navLogo {
    width: 56px !important;
    margin-left: 20px !important;
  }
  .peranav {
    font-size: 12px !important;
  }
  .logoutNav {
    margin: 0 !important;
    padding: 0 !important;
    width: 55px !important;
  }
  .fonttab {
    font-size: 13px !important;
  }
  .maincontainerhead {
    padding: 0 3px !important;
  }

  .mainscrollnav {
    width: 100% !important;
    overflow: scroll !important;
  }
  .scrolldivtwo {
    overflow-y: scroll !important;
    overflow-x: scroll !important;
    width: 1500px !important;
    padding: 0 0 4px 0 !important;
  }
  .scrolldivtwo::-webkit-scrollbar {
    display: none;
  }
  .mainscrollnav::-webkit-scrollbar {
    display: none;
  }
} */


.floating-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  /* margin-top: 100px; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex ;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  z-index: 9999;
   
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}


