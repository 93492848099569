@media only screen and (max-width: 950px) {
    .clinettablelist {
        width: 100% !important;
        height: auto !important;
        overflow: scroll !important;
      }
      .clinettablelistdivtwo {
        width: 1450px !important;
        overflow-x: scroll !important;
        overflow-y: scroll !important;
      }
      .textfont{
        font-size: 12px !important;
     
      }
      .fontpera{
        font-size: 10px !important;
      }
      .paddingmobile{
        padding: 0 !important;
        font-size: 12px !important;
      }
      .paddingmobileo{
        font-size: 10px !important;
      }
      .icondoler{
        font-size: 16px !important;
      }
      .inputtextmobilediv {
        display: block !important;
        margin: 0 25px !important;
        padding: 0 !important;

      }
      .backgroundchange{
        background-color: #BFE8FF !important;
      }
      .heightscrollsecond{
        max-height: 260px !important;
      }
     
      .inputtextmobile  {
        display: block !important;
        width: 100% !important;
        display: flex !important;
        margin-top: 0px !important;
        margin-bottom: 10px !important;
      }
      .displayjustify{
        justify-content: center !important;
        margin-top: 12px !important;
      }
      .invoicedisplay{
        flex-direction: column !important;
      }
      .invoicegenerated{
        font-size: 14px !important;
        margin: 0 0 15px 0 !important;
      }
      .justifycontenttt{
        justify-content: center !important;
      }
      .widthhund{
        width: 100% !important;
        overflow: hidden !important;
      }
  }
  