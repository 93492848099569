@media only screen and (max-width: 950px) {
  .reudisplaynone {
    display: none !important;
  }
  .displblock {
    display: block !important;
    width: 100% !important;
    
  }
  
  .scrollmain {
    overflow: scroll !important;
    width: 100% !important;
    display: block !important;
    margin-bottom: 20px !important;
    border-bottom: 1px solid #f9eaea !important;
  }
 
  .scrollreqursion {
    overflow-x: scroll !important;
    overflow-y: scroll !important;
    width: 1150px !important;
  }
  .popaddreq {
    padding: 0 !important;
  }
  .closeico {
    margin: 0 !important;
  }
  .backgroundmain{
    
    background-color: #BFE8FF !important;
  }
  .textfont{
    font-size: 12px !important;
    padding: 10px 5px !important;
  }
  .maintextfont{
    font-size: 10px !important;
    padding: 10px 5px !important;
  }
  /* poppup add requisition */

  .mainpopup {
    height: auto !important;
    width: 100% !important;
    margin: 0 !important;
  }
  .addpadding{
    padding: 0 !important;
  }
  .popupcontain {
    display: block !important;
    margin: 0 !important;
    width: 100% !important;
    
  }
  .popupcontaininput {
    width: 100% !important;
    height: 40px !important;
    }
  .selectdive{
    width: 100% !important;
    height: 40px !important;
  }
  .heightpopup {
    height: auto !important;
    width: 100% !important;
    margin: 0 !important;
  }
  .gapcol {
    column-gap: 0 !important;
    justify-content: space-between !important;
  }
  .popscrollmain {
    overflow: scroll !important;
    width: 100% !important;
  }
  .popscrollr {
    overflow-x: scroll !important;
    overflow-y: scroll !important;
    width: 811px !important;
  }
  .displaynone {
    display: none !important;
  }
  .displaymobile {
    display: block !important;
  }
  .savebutton {
    width: 100% !important;
    margin-bottom: 25px !important;
  }


  /* AddSpoc */


  .addspocmaindiv{
   width: 100% !important;
   bottom: 100px !important;
   left: 0 !important;
   height: auto !important;
  }
  .centerAddspoc{
    row-gap: 0 !important;
    font-size: 12px !important;
  }
  .fontslide{
    font-size: 12px !important;
  }
  .fontperascrol{
    font-size: 10px !important;
  }
  .widthfull{
    width: 100% !important;
  }
 
}


/* .disabled-row {
  opacity: 0.5; 
  pointer-events: none;
} */
