/* style.css */

/* Global Styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.App {
  display: flex;
}

/* Sidebar Styles */
.Sidebar {
  width: 200px;
  background-color: #333;
  color: #fff;
  padding: 15px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: width 0.3s ease; /* Added transition for smooth effect */
}

.ButtonsContainer {
  display: flex;
  flex-direction: column;
}

.ButtonContainer {
  background-color: #555;
  color: #fff;
  padding: 30px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ButtonContainer:hover {
  background-color: #e54f4f;
}

/* Report Container Styles */
.ReportContainer {
  flex: 1;
  padding: 20px;
  background-color: #e2e4e4;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.ReportClass {
  height: 800px;
  max-width: 100%;
}

.sidebar-closed .Sidebar {
  width: 0;
  overflow: hidden;
}

/* Add a class to the App container to toggle the sidebar */
.sidebar-closed .App {
  margin-left: 0;
}

.ToggleSidebarButton {
  position: sticky;
  top: 0;
  /* right: 10px; */
  /* background-color: #555; */
  /* color: #fff; */
  /* padding: 10px; */
  border: none;
  z-index: 500;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ToggleSidebarButton:hover {
  background-color: #e54f4f;
}
