@media only screen and (max-width: 950px) {
  .inputtext {
    display: none !important;
  }
  .inputtextmobilediv {
    display: block !important;
    margin: 0 25px !important;
  }
  .inputtextmobile {
    display: block !important;
    width: 100% !important;
    display: flex !important;
    margin-bottom: 10px !important;
    margin-top: 0 !important;
  }
  .clint_listMainDiv {
    display: block !important;
    margin: 0 !important;
  }
  .clientdivOne {
    width: 100% !important;
    display: block !important;
  }
  .clientdivTwo {
    width: 100% !important;
    display: block !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .clientdivchild {
    height: auto !important;
  }
  
  .addclintone {
    display: block !important;
    margin-left: 0 !important;
    width: 100% !important;
  }
  .mar{
    margin-top: 14px;
  }
  .addclintone_firstname {
    height: 35px !important;
    width: 100% !important;
    margin-left: 0 !important;
  }
  .addclintone_sop {
    width: 100% !important;
    margin-left: 0 !important;
    margin-top: 12px !important;
  }
  .inputfildheight {
    height: 20px !important;
  }
  .textalinepera{
    text-align: start !important;
    margin: 20px 0 0 0 !important;
    /* font-size: 12px !important; */
  }
  .emailmargin {
    margin-top: 10px;
  }
  .clinettablelist {
    width: 100% !important;
    overflow: scroll !important;
  }
  .clinettablelistdivtwo {
    width: 675px !important;
    overflow-x: scroll !important;
    overflow-y: scroll !important;  
  }

  .fontsiz{
    font-size: 12px !important;
  }
  .fontrchild{
    font-size: 10px !important;
    padding: 0 !important;
  }
  /* .css-19kzrtu {
    padding: 0px !important;
} */

  .clientTeamDiv {
    width: 100% !important;
  }
  .distag{

    font-size: 12px !important;
  }
  .textfont{
    font-size: 10px !important;
  }
 
  .typeofhiring{
    text-align: start !important;
    font-size: 13px !important;
  }
  .fontsizemain{
    font-size: 14px !important;
  }
  .clientteam {
    width: 100% !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    padding: 20px 20px !important;
  }
  .savebuttonclientlist {
    width: 100% !important;
  }

  .fonttext {
    font-size: 14px !important;
  }
  .backgroundmain {
    background-color: #bfe8ff !important;
  }
  .textfont {
    font-size: 12px !important;
    padding: 10px 5px !important;
  }
  .maintextfont {
    font-size: 10px !important;
    padding: 10px 5px !important;
  }
  .mainpad {
    padding: 0 !important;
  }

  /* popupsaveclientlist */

  .popup {
    padding: 0 !important;
  }
  .closeicon {
    margin: 0 !important;
  }
  .gaprow {
    row-gap: 0 !important;
  }

  .maincontainer {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    height: auto !important;
  }

  .updatmaindiv {
    display: flex !important;
    flex-direction: column !important;
    column-gap: 0 !important;
    /* gap: 0 !important; */
  }
  .inputfiledupdate {
    height: 38px !important;
    margin: 0 !important;
    width: 100% !important;
    margin-top: 5px !important;
    padding: 0 !important;
  }
  .mainscroll {
    width: 100% !important;
    overflow: scroll !important;
    text-align: center !important;
  }
  .scrolldivtwo {
    overflow-y: scroll !important;
    overflow-x: scroll !important;
    /* width: 700px !important; */
  }
  .textcenter {
    text-align: center !important;
  }
  .tabone {
    width: 100% !important;
  }
  .savebutton {
    width: 100% !important;
    margin-top: 25px !important;
  }

  .tabcolum {
    flex-direction: column !important;
  }
  .tabwidth {
    /* width: 100% !important; */
    margin: 5px 0 !important;
    height: 35px !important;
  }
}
