@media only screen and (max-width: 950px) {
  .inputnonmobilecanstatus {
    display: none !important;
  }
  .candidstatusmaindiv {
    width: 100% !important;
  }
  .candidatestatusdis {
    display: none !important;
  }
  .candidatestatusdismobile {
    display: block !important;
  }
  .MuiBox-root css-19kzrtu {
    padding: 0 !important;
  }
  .mobilepadding {
    padding: 0 5px !important;
  }
  .inputtextmobilediv {
    display: block !important;
    margin: 0 25px !important;
  }
  .inputtextmobile {
    display: block !important;
    width: 100% !important;
    display: flex !important;
    margin-bottom: 10px !important;
    margin-top: 0 !important;
  }
  .paginationmobile {
    width: 100% !important;
    display: block !important;
  }
  /* .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{
display: block !important;
  }
  .flexcontain{
    justify-content: center !important;
  } */
}
