@media only screen and (max-width: 950px) {
  .editmastermobile {
    display: block !important;
    right: 50px !important;
    top: 58px !important;
    width: 72px !important;
    position: relative !important;
  }
  .editmaste {
    font-size: 12px !important;
  }
  .internalreport {
    position: relative !important;
    top: 25px !important;
    right: 5px !important;
    font-size: 15px !important;
    font-size: 12px !important;
  }

  .userrolemain {
    width: 100% !important;
    overflow: scroll !important;
  }
  .userrolescroll {
    overflow-y: scroll !important;
    overflow-x: scroll !important;
    min-width: 850px !important;
    
  }
  .inputtext {
    display: none !important;
  }
  .inputtextmobilediv {
    display: block !important;
    margin: 0 25px !important;
  }
  .inputtextmobile  {
    display: block !important;
    width: 100% !important;
    display: flex !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
  }
  .clint_listMainDiv {
    display: block !important;
    margin-top: 12px !important;
    /* margin-bottom: 0 !important; */
  }
  .clientdivOne {
    width: 100% !important;
    display: block !important;
  }
  .clientdivTwo {
    width: 100% !important;
    display: block !important;

    /* margin-top: 20px !important; */
  }

  .clientdivchild {
    text-align: center !important;
    padding: 0 !important;
    height: auto !important;
    justify-content: center !important;
    width: 100% !important;
    margin: 0 !important;
    font-size: 12px !important;
  }
  .userlistdiv {
    width: 100% !important;
    height: auto !important;
    padding: 20px 0 !important;
    margin-bottom: 15px !important;
    border: 0 !important;
  }

  .userdetail {
    display: none !important;
  }

  .disp{
    display: none !important;
  }
  .gapzero{
    gap: 2px !important;
  }
  .userdetailmobile {
    font-size: 12px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 0 !important;
  }
  .userd {
    font-size: 12px !important;
    display: block !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .divuser {
    margin-bottom: 10px !important;
  }

  

  .savebuttonclientlist {
    width: 100% !important;
  }
  .magtop{
    margin: 0 !important;
  }

  
  .backgroundmain{
    
    background-color: #BFE8FF !important;
  }
.textfont{
  font-size: 12px !important;
  padding: 10px 5px !important;
}
.maintextfont{
  font-size: 10px !important;
  padding: 10px 5px !important;
}

  .fonttext{
    font-size: 14px !important;
  }

  /* updatelist component */

  .userupdate {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
  }
  .paddupdate {
    padding: 0 !important;
  }
  .userpopupmobile {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 0 !important;
    padding: 0 15px !important;
  }

  .savebox{
    width: 100% !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}
